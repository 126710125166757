/* eslint-disable */
export default `
  <h2>
    Publishing Terms (Central Repository)
  </h2>
  (Last updated on March 3, 2020)

  ​
  <h3>
    Introduction
  </h3>

  <ul>
    <li>
      1. Acceptance of Terms
    </li>
    <li>
      2. Accounts, Passwords and Security
    </li>
    <li>
      3. Permitted Uses
    </li>
    <li>
      4. Submissions to the Central Repository
      <ul>
        <li>
          a. You Take Responsibility for Your Submissions.
        </li>
        <li>
          b. License Grant.
        </li>
      </ul>
    </li>

    <li>
      Enforcement of Copyrights
    </li>

    <li>
      Indemnity for Submissions
    </li>

    <li>
      Effect of Termination
    </li>

    <li>
      General Terms
    </li>
  </ul>
​
  <h3>
    Introduction
  </h3>
  <p>
    The Central Repository is provided by MLReef to the public as a community service. There is no charge to use the Central Repository, and MLReef pays all the costs for hosting, bandwidth, etc. The components made available for download via Central are licensed to users by the developers who posted them and are subject to the terms and conditions of the applicable licenses accompanying such components.
  </p>

  ​
  <p>
    You may submit code to the Central Repository provided you grant us a license to distribute such code to the public. Any code you provide will be available to the public subject to the terms of the licenses you indicate upon submission.
  </p>

  ​
  <p>
    You are responsible for the submissions you publish to the Central Repository. Once published the submission is made available to the public and may be mirrored to other locations for redundancy and backup purposes. That submission is static once published and will not be changed or removed unless it violates third party intellectual property rights or is otherwise harmful to users. Questions and concerns not related to intellectual property rights (including copyright violations) may be submitted by email to help@mlreef.com.
  </p>

  ​
  <p>
    Submissions published to the Central Repository must meet minimum standards in terms of metadata and other quality criteria that are adjusted from time to time. These quality standards are intended to improve everyone's experience and ability to use the content. The standards and guides to achieve the standards are documented in the requirements page.
  </p>

  ​
  <p>
    This has just been an overview of some key points relating to making submissions to the Central Repository. Use of the Central Repository in any way is subject to our Central Repository Terms of Service and, if you do decide to make submissions, you will also be subject to our Central Provider Terms set forth below.
  </p>

  ​
  <h3>
    1. Acceptance of Terms
  </h3>
  <p>
    Welcome! MLReef GmbH. ("MLReef" or "us" or "we") welcomes you to the Central Repository and other similar services provided by MLReef at mlreef.com and related sub-domains (collectively, "Central"). The Central Repository is a software component repository hosted by MLReef, where third-party developers may post code to their projects for download by users.
  </p>

  ​
  <p>
    Your use of the Central Repository is subject to our Central Repository Terms of Service. If you elect to make submissions to Central, then you also agree to these Central Repository Producer Terms and all other policies, standards, guidelines or notices posted by us on our websites. When we refer to the "Terms of Service" we mean the Central Repository Terms of Service as supplemented by these Central Repository Producer Terms.
  </p>
  <p>
    Any capitalized terms not defined in these Central Repository Producer Terms will have the meaning given to them in the Central Repository Terms of Service. In the event of a direct conflict between the Central Repository Terms of Service and these Central Repository Producer Terms, the Central Repository Producer Terms will control.
  </p>

  ​
  <p>
    If you don't agree to the Terms of Service, you may not use the Central Repository or make submissions. We may change the Terms of Service at any time without any notice to you. It is your responsibility to review the Terms of Service from time to time for any changes as it creates binding legal agreement between you and MLReef. If you use the Central Repository or make submissions after we've changed any of the Terms of Service, you are agreeing to all of the changes. Again, if you do not agree, don't use the Central Repository.
  </p>

  ​
  <h3>
    2. Accounts, Passwords and Security
  </h3>
  <p>
    You may need to set up an account in order to make submissions to the Central Repository. You may not use someone else's account without permission. When you are setting up your account, you must give us accurate and complete information. This means that you cannot set up an account using someone else's name or contact information, or a phony name or phony contact information. You have complete responsibility for your account and everything that happens on your account. This means you need to be careful with your password. If you find out that someone is using your account without your permission, you must let us know immediately. You may not transfer your account to someone else. We are not liable for any damages or losses caused by someone using your account without your permission. However, if we (or anyone else) suffer any damage due to the unauthorized use of your account, you may be liable.
  </p>

  ​
  <h3>
    3. Permitted Uses
  </h3>
  <p>
    If you elect to make submissions to the Central Repository, you may access the Central Repository to make submissions available for consumption by others.
  </p>

  ​
  <h3>
    4. Submissions to the Central Repository
  </h3>
  <p>
    The Central Repository offers many opportunities for you to submit Materials to be made publicly available. "Materials" means source code, code samples, object code, encryption keys, technical materials, documentation, discussion thread postings, weblogs, wikis, data and any other content, information or technology available via the Central Repository. The following terms apply to any Materials submitted by you to the Central Repository ("Submissions").
  </p>
  ​
  <p>
    a. You Take Responsibility for Your Submissions. You warrant that you have all rights needed to provide your Submissions to MLReef for posting to the Central Repository in accordance with these Central Repository Producer Terms and to grant the licenses set forth in section
  </p>
​    <p>
    4.b. You agree that you will make all reasonable efforts to ensure that your Submissions: (i) do not infringe, misappropriate or violate the intellectual property rights or privacy interests of others; (ii) are not confidential or trade secret information, or subject to anyone's privacy interest; (iii) are not inaccurate, harmful, obscene, pornographic, defamatory, racist, or otherwise objectionable to a reasonable user; and (iv) do not violate any law, ordinance, or regulation of any country, state or locality. YOU AGREE THAT YOU, AND NOT MLREEF, ARE FULLY RESPONSIBLE FOR YOUR SUBMISSIONS AND THAT YOU, AND NOT MLREEF, ARE LIABLE FOR ANY AND ALL CLAIMS ARISING OUT OF THEM.
  </p>
  ​
  <h4>
    b. License Grant.
  </h4>
  <p>
    You agree that any code you post will be submitted under, and subject to, an end user license agreement or terms of use. You acknowledge that you are responsible for including all applicable copyright notices and licenses with your Submissions, and that you assume the risks of failing to do so, including the potential loss of your rights to your Submissions. MLReef does not claim ownership of your Submissions. However, in order to fulfill the purposes of the Central Repository, you must give MLReef the right to make your Submissions available to users. In legalese: You hereby grant to MLReef a royalty-free, perpetual, irrevocable, worldwide, non-exclusive and fully sub-licensable right and license under your intellectual property rights to reproduce, publish, distribute, perform and display your Submissions (in whole or in part) solely for the purpose of copying, storing and hosting them for download by users of the Central Repository, all subject to the obligation to retain any copyright notices included in your Submissions and to only make such Submissions available to users under the license you have indicated at the time of submission.
  </p>
​
  <h3>
    Enforcement of Copyrights
  </h3>
  <p>
    We respect the intellectual property rights of others. You may not use the Central Repository to infringe anyone else's copyright or other intellectual property right. If we find out that you are infringing, we will remove your Submissions. We do not have to give you notice that we are removing your Submissions. We may also terminate your account, if we decide that you are a repeat infringer. We consider a repeat infringer to be a user who has been notified of infringing activity more than twice or who has had Submissions removed from the Central Repository more than twice.
  </p>
  ​
  <h3>
    Indemnity for Submissions
  </h3>
  <p>
    You agree to indemnify and hold harmless MLReef and its affiliates, suppliers, partners, officers, agents, and employees from and against any claim, demand, losses, damages or expenses (including reasonable attorney's fees) arising from your Submissions.
  </p>

  ​
  <h3>
    Effect of Termination
  </h3>
  <p>
    Upon any termination we may delete your account, passwords and Submissions and we may bar you from further use of the Central Repository. You understand that we may also continue to make your Submissions available on the Central Repository even if your use of the Central Repository is terminated or suspended. You agree that we will have no liability to you or any third party for termination of your account, Submissions or access to the Central Repository.
  </p>

  ​
  <h3>
    General Terms
  </h3>
  <p>
    As noted above, your activities in making Submissions to the Central Repository are governed by the Central Terms of Service as supplemented by these Central Repository Producer Terms.
  </p>
  <p>
    © 2022 by MLreef GmbH.
  </p>

`
