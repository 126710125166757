import React from 'react';
import Layout from '../layout';
import publishingTerms from '../data/publishingTerms';

const PublishingTermsPage = () => (
  <Layout className="simple-page">
    <div className="simple-page-top-banner-container">
      <h1 className="simple-page-top-banner-title">Publishing Terms (Central Repository)</h1>
    </div>
    <div className="simple-page-content">
      <div dangerouslySetInnerHTML={{ __html: publishingTerms }} />
    </div>
  </Layout>
);

export default PublishingTermsPage;
